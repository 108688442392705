import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { LoanDto, NewLoanDTO, UpdateLoanDTO } from 'src/backend';
import { loanApi } from 'src/services/loanApi';
import { AppThunkPromise } from 'src/store';


interface LoanManagerState {
    loans: Record<string, LoanDto>;
}

const initialState: LoanManagerState = {
    loans: {},
};

export const loansManagerSlice = createSlice({
    name: 'loan-manager',
    initialState,
    reducers: {
        appendLoans(state: LoanManagerState, action: PayloadAction<{ loans: LoanDto[] }>): void {
            // update current state or add new loans
            action.payload.loans.forEach(loan => {
                if (!isEqual(state.loans[loan.id], loan)) {
                    state.loans = {
                        ...state.loans,
                        [loan.id]: {
                            ...state.loans[loan.id],
                            ...loan,
                            userProgress: !!loan.userProgress ? loan.userProgress : state.loans[loan.id]?.userProgress,
                        }
                    };
                }
            });
        },
        prependLoans(state: LoanManagerState, action: PayloadAction<{ loans: LoanDto[], page: number, phaseId: string }>): void {
            // update current state or add new loans
            action.payload.loans.forEach(loan => {
                if (!isEqual(state.loans[loan.id], loan)) {
                    state.loans = {
                        [loan.id]: {
                            ...state.loans[loan.id],
                            ...loan,
                            userProgress: !!loan.userProgress ? loan.userProgress : state.loans[loan.id]?.userProgress,
                        },
                        ...state.loans
                    };
                }
            });
        },
        setLoans: (state, action: PayloadAction<Record<string, LoanDto>>) => {
            Object.keys(action.payload).forEach((key) => {
                state.loans[key] = action.payload[key];
            });
        },
        removeLoan(state, action: PayloadAction<string>): void {
            delete state.loans[action.payload];
        },
    }
});

export const createV2Loan = (loan: NewLoanDTO): AppThunkPromise<LoanDto> => async (dispatch: any): Promise<LoanDto> => {
    const result = await api.createV2Loan(loan);
    dispatch(actions.prependLoans({ loans: [result], page: 1, phaseId: result.loanPhase?.id }));
    return result;
}

export const updateV2Loan = (id: string, loan: UpdateLoanDTO): AppThunkPromise<LoanDto> => async (dispatch: any): Promise<LoanDto> => {
    const result = await api.updateV2Loan(id, loan);
    dispatch(actions.appendLoans({ loans: [result] }));
    dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id }]));
    return result;
}

export const { reducer, actions } = loansManagerSlice;
